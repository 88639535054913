exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bubbbbbbles-js": () => import("./../../../src/pages/bubbbbbbles.js" /* webpackChunkName: "component---src-pages-bubbbbbbles-js" */),
  "component---src-pages-bubbbbbles-js": () => import("./../../../src/pages/bubbbbbles.js" /* webpackChunkName: "component---src-pages-bubbbbbles-js" */),
  "component---src-pages-dj-js": () => import("./../../../src/pages/dj.js" /* webpackChunkName: "component---src-pages-dj-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-links-js": () => import("./../../../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-pages-mix-master-js": () => import("./../../../src/pages/mix-master.js" /* webpackChunkName: "component---src-pages-mix-master-js" */),
  "component---src-pages-releases-js": () => import("./../../../src/pages/releases.js" /* webpackChunkName: "component---src-pages-releases-js" */),
  "component---src-pages-scores-js": () => import("./../../../src/pages/scores.js" /* webpackChunkName: "component---src-pages-scores-js" */),
  "component---src-pages-vita-js": () => import("./../../../src/pages/vita.js" /* webpackChunkName: "component---src-pages-vita-js" */)
}

